<template>
  <div class="page">
    <el-form :model="formInfo" label-width="140px" ref="formInfo">
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title">基础信息</span>
          <div class="button-back">
            <el-button @click="() => {
      $router.go(-1);
    }
      " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="来源应用" prop="app_type_shop_type_flag">
                <el-select style="width: 220px" :disabled="disabled" size="medium" v-model="formInfo.shop_type"
                  clearable>
                  <el-option label="全部" value></el-option>
                  <el-option label="现金商城" :value="1"></el-option>
                  <el-option label="双选商城" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="client_name">
                <el-select size="small" :disabled="disabled" placeholder="请选择客户名称" v-model="formInfo.customer_name"
                  clearable style="width: 200px">
                  <el-option v-for="item in customerList" :key="item.id" :value="item.name" :label="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理名称">
                <el-autocomplete placeholder="代理名称" style="width: 100%" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('AGENCY')" :fetch-suggestions="agentScreen" size="small"
                  v-model.trim="formInfo.agent_person" :disabled="disabled" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人名称">
                <el-autocomplete placeholder="联系人名称" style="width: 100%" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('LINKMAN')" :fetch-suggestions="linkNameScreen" size="small"
                  v-model.trim="formInfo.contacts_person" :disabled="disabled" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="联系电话">
                <el-input v-model.trim="formInfo.contacts_phone" :disabled="disabled" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售人员">
                <en-sale-search placeholder="请输入销售人员" v-model="formInfo.seller_name" :disabled="disabled"
                  @change="handleSelectSell" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制单人名称">
                <el-autocomplete style="width: 100%" :trigger-on-focus="false" placeholder="请输入制单人名称"
                  @focus="returnMatchSuggestion('WRIGHT')" :fetch-suggestions="makeOrderScreen" size="small"
                  v-model.trim="formInfo.operator_name" :disabled="true" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-input v-model.trim="formInfo.customer_address" :disabled="disabled" clearable></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">充值用户信息</span>
        </template>
        <div class="card-body-1">
          <el-form-item label="所属商城">
            <el-tooltip :content="formInfo.shop_name" :disabled="(formInfo.shop_name || '').length < 12" placement="top"
              effect="light">
              <el-select size="small" v-model="formInfo.shop_id" filterable clearable style="width: 200px"
                :disabled="disabled">
                <el-option v-for="(item, index) in shopList" :key="index" :value="item.shop_id"
                  :label="item.shop_name" />
              </el-select>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="充值账号">
            <el-table style="width:750px" height="350" v-if="formInfo.account_type == 1"
              :data="formInfo.trade_recharge_member_dos" border>
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input :disabled="disabled" size="small" v-model.trim="scope.row.user_name"
                    placeholder="姓名"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="手机号">
                <template slot-scope="scope">
                  <div style="padding:6px 0">
                    <el-input :disabled="disabled" size="small" v-model.trim="scope.row.phone_number" placeholder="手机号"
                      maxlength="11"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="充值金额">
                <template slot-scope="scope">
                  <el-input :disabled="disabled" size="small" v-model.trim="scope.row.money"
                    placeholder="充值金额"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <el-table style="width:750px" height="350" v-else :data="formInfo.trade_recharge_member_dos" border>
              <el-table-column label="姓名">
                <template slot-scope="scope">
                  <el-input :disabled="disabled" size="small" v-model.trim="scope.row.user_name"
                    placeholder="姓名"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="账号">
                <template slot-scope="scope">
                  <el-input :disabled="disabled" size="small" v-model.trim="scope.row.account"
                    placeholder="手机号"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="充值金额">
                <template slot-scope="scope">
                  <el-input :disabled="disabled" size="small" v-model.trim="scope.row.money"
                    placeholder="充值金额"></el-input>
                </template>
              </el-table-column>

            </el-table>
          </el-form-item>
          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="折扣率">
                <el-input :disabled="disabled" style="width:140px;text-align:center;"
                  oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                  v-model="formInfo.discount_rate"><template slot="append">%</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="充值人数">
                <el-input size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.recharge_number" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.total_money" :disabled="disabled"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.discounts_total_money" :disabled="disabled"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" placeholder="请输入200字以内的备注信息" rows="5" resize="none"
              v-model.trim="formInfo.remark" :maxlength="200" :disabled="disabled" />
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { Foundation } from "@/../ui-utils";
import * as API_saleCard from "@/api/saleCard";
import mixins from "./saleOrderRefundMixins";
import * as API_Order from "@/api/order";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";
import { getAllCustomerListByEnterpriseId } from "@/api/settingCustomer";
export default {
  name: "rechargeSalesDetail",
  mixins: [mixins],
  components: {
    cardList,
    cardUpload,
  },
  data () {
    return {
      customerList: [],//客户档案列表
      phoneTableData: [//手机号类型
        {
          name: '',
          mobile: '',
          money: '',
          mobileError: '',
          moneyError: ''
        }
      ],
      userTableData: [{//账号类型
        name: '',
        account: '',
        money: '',
        accountError: '',
        moneyError: ''
      }],
      double_choice_mall_open: true,//是否开启双选商城
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      disabled: true,
      textarea: "",
      shop_card_info_list: [],
      formInfo: {
        accountType: 0,//账号类型
        message: 1,//是否短信通知用户
        app_type_shop_type_flag: "",
        shop_id: "",
        shop_name: "",
        client_name: "",
        agent_name: "",
        link_name: "",
        link_phone: "",
        client_addr: "",
        sell_name: "",
        make_order_name: !this.$route.query.id ? (this.$store.getters.user.nickname || "") : "",
        cardList: [],
        total_price: 0,
        after_discount_price: 0,
        remark: "",
      },
      saveDisabled: false,
      shopCardList: [],
      tempCardList: [], // 匹配后的卡券列表
      phoneError: "",
      disabledCardNum: false, // 禁用卡券数量
      trade_sn: "",
      disabledColumn: ["面值/价位"]
    };
  },

  async mounted () {
    this.customerList = await getAllCustomerListByEnterpriseId(this.$store.getters.shopInfo.shop_id)
    API_Order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    //查看详情
    this.getDataInfo();
    this.getClientList();
  },
  methods: {
    async getDataInfo () {
      const res = await API_saleCard.getMemberDetail(this.id)
      this.getShopList(res.shop_type);
      res.discount_rate = Foundation.rideitionNumber(res.discount_rate, 100);
      this.formInfo = res;
    },
    changeAppType (v) {
      this.$nextTick(() => {
        this.formInfo.cardList = [];
        this.shop_card_info_list = [];
        this.$refs.card_list.clear();
        this.$refs.cul.clear();
      });
      this.formInfo.shop_id = "";
      this.formInfo.shop_name = "";
      if (v) {
        this.getShopList(this.formInfo.app_type_shop_type_flag);
      } else {
        this.shopCardList = [];
        this.shopList = [];
      }
    },
    setRangeCardList ({ item, index, cards }, res) {
      const { shop_id, shop_name, app_type_shop_type_flag } = item;
      let discount_ratio_org = item.discount_ratio;
      cards[shop_id] = res.card_range_list;
      // console.log(item);
      this.shop_card_info_list = [];

      for (const [key, value] of Object.entries(cards)) {
        if (key != "") {
          let _value = [...value];
          // const { card_code_start, card_code_end, card_num } = _value[index];
          // console.log(_value[index]);
          if (key == shop_id) {
            _value[index] = {
              ..._value[index],
            };
          }
          let sitem = value.find((v) => v.shop_id == key);
          this.shop_card_info_list.push({
            shop_id: key,
            shop_name: sitem ? sitem.shop_name : "",
            app_type_shop_type_flag,
            card_range_list: _value,
          });
        }
      }
      let cardList = this.shop_card_info_list
        .map((shop) => {
          let list = [];
          let first = shop.card_range_list[0];
          shop.card_range_list.forEach((item) => {
            let discount_ratio =
              item.discount_ratio == null
                ? discount_ratio_org
                : item.discount_ratio;
            if (first.unify_discount_rate_status) {
              discount_ratio = +first.unify_discount_rate;
            }
            list.push({
              ...item,
              unify_discount_rate: +first.unify_discount_rate,
              unify_discount_rate_status: !!first.unify_discount_rate_status,
              discount_ratio,
            });
          });
          return [...list];
        })
        .flat();



      let nlist = cards[""];
      let clist = [...cardList];
      if (nlist) {
        clist.push(nlist[0]);
      }
      this.formInfo.cardList = clist;
    },
    async changeCardRange ({ item, index, list, cards, type }) {
      const { shop_id, shop_name } = item;
      const { app_type_shop_type_flag } = this.formInfo;
      const { trade_sn } = this;
      let params = {
        shop_id,
        shop_name,
        app_type_shop_type_flag,
        card_range_index: type == "num" ? null : index,
        card_range_list: [...list],
        change_num_card_id: item.card_id,
      };
      if (this.isEdit) params["trade_sn"] = trade_sn;

      API_saleCard.editNewCardCouponInfo(params)
        .then((res) => {
          if ((res.not_inactive_card_range || []).length > 0) {
            this.checkCardRange([...res.not_inactive_card_range], 1).then(
              (type) => {
                if (type == 1) {
                  this.setRangeCardList({ item, index, cards }, res);
                } else {
                  this.$refs.card_list.backtrack();
                }
              }
            );
            return;
          }
          this.setRangeCardList({ item, index, cards }, res);
        })
        .catch((err) => {
          console.error(err);
          this.$refs.card_list.backtrack();
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  position: relative;
}

.errorMessage {
  font-size: 12px;
  color: red;
  position: absolute;
  left: 11px;
  bottom: -3px;
}
</style>
